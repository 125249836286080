<template>
    <styled-slideout
        class="organization-settings-slideout"
        :value="value"
        :width="500"
        label="Whitelabel Settings"
        @input="$emit('input', $event)">
        <p
            v-if="!showAlert"
            class="px-4 pt-3 pb-2">
            On this dashboard you will be able to edit Whitelabel setting for your Organization.
        </p>
        <v-alert
            v-model="showAlert"
            :type="alertType"
            class="mb-4">
            {{ alertMessage }}
        </v-alert>
        <v-flex>
            <h3
                class="px-4">
                User Interface Overrides
            </h3>
            <v-flex
                class="px-4">
                <p
                    class="py-2">
                    As the Support button on the Nav Bar redirects directly to BuyerBridge support, you can disable it
                    for users who access your Organization page.
                </p>
                <v-layout
                    row
                    justify-center
                    class="pb-3">
                    <img
                        alt="image of support button in nav bar"
                        src="/img/support-nav.png">
                </v-layout>
                <v-layout
                    id="switch"
                    align-center
                    justify-center>
                    <v-layout
                        class="pr-2"
                        justify-end>
                        <v-label id="font" />
                    </v-layout>
                    <v-switch
                        v-model="config[orgConfiguration.SUPPORT_BUTTON_DISABLED]"
                        :true-value="false"
                        :false-value="true"
                        color="info"
                        :class="config[orgConfiguration.SUPPORT_BUTTON_DISABLED] ? 'v-input--reverse' : null">
                        <template
                            v-if="config[orgConfiguration.SUPPORT_BUTTON_DISABLED]"
                            #label>
                            Hide
                        </template>
                        <template
                            v-else
                            #label>
                            Show
                        </template>
                    </v-switch>
                </v-layout>
            </v-flex>
            <v-flex>
                <p
                    class="px-4 py-4">
                    The links on the footer who redirect to BuyerBridge's Privacy Policy and Terms can be overwritten using the form below.
                    By default, if no link is given, it will use BuyerBridge one.
                </p>
                <v-checkbox
                    v-model="checkboxValue"
                    color="info"
                    class="px-4 styled-checkbox"
                    label="Override BuyerBridge Privacy Policy and Our Terms"
                    @change="handleClickEvent" />
                <v-input />
                <v-form
                    id="overwrite-links-form"
                    ref="overwriteLinksForm"
                    class="text-xs-center">
                    <v-text-field
                        v-model="config[orgConfiguration.PRIVACY_POLICY]"
                        label="Privacy Policy"
                        required
                        :hint="urlHint"
                        :disabled="!checkboxValue"
                        :rules="urlValidationRules"
                        class="py-4 px-5" />
                    <v-text-field
                        v-model="config[orgConfiguration.TERMS]"
                        label="Terms"
                        required
                        :hint="urlHint"
                        :disabled="!checkboxValue"
                        :rules="urlValidationRules"
                        class="py-4 px-5" />
                </v-form>
            </v-flex>
            <v-layout
                justify-center
                row>
                <v-btn
                    color="info"
                    @click="saveConfig">
                    save
                </v-btn>
            </v-layout>
        </v-flex>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';
import { mapActions, mapState } from 'vuex';
import { isEqual, merge } from 'lodash';
import orgConfiguration from '@/helpers/organizations/configuration';
import isValidHttpUrl from '@/helpers/isValidHttpUrl';

export default {
    name: 'OrganizationSettingsSlideout',
    components: {
        StyledSlideout,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            orgConfiguration,
            showAlert: false,
            alertState: null,
            checkboxValue: false,
            config: {},
            urlHint: 'URLs should contain HTTP or HTTPS e.g https://www.google.com',
            urlValidationRules: [
                (url) => isValidHttpUrl(url) || 'URL is invalid',
            ],
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency,
        }),
        alertType() {
            return this.alertState === 'ERROR' ? 'error' : 'success';
        },
        alertMessage() {
            return this.alertState === 'ERROR' ?
                'Some error has occurred, please try again in a few minutes. If it persists, contact support.' :
                `You have successfully updated the Whitelabel settings for ${this.currentAgency.name}`;
        }
    },
    mounted() {
        /**
         * In case something here breaks because it's not set we can set a default value
         * The merge method for lodash will overwrite it if any value is set on the original config
         */
        const defaultObjectIfConfigNotSet = {
            [orgConfiguration.SUPPORT_BUTTON_DISABLED]: false,
        };

        this.config = merge(
            defaultObjectIfConfigNotSet,
            this.currentAgency.configuration
        );

        this.checkboxValue = this.checkIfLinksAreFilled();
    },
    methods: {
        ...mapActions([
            'updateOrganizationConfiguration'
        ]),
        checkIfLinksAreFilled() {
            return !!this.config?.[orgConfiguration.PRIVACY_POLICY] || !!this.config?.[orgConfiguration.TERMS];
        },
        handleClickEvent() {
          if(!this.checkboxValue) {
              this.config[orgConfiguration.PRIVACY_POLICY] = null;
              this.config[orgConfiguration.TERMS] = null;
              this.$refs.overwriteLinksForm.reset();
          }
        },
        async saveConfig() {
            try {
                if(!isEqual(this.config, this.currentAgency.configuration)) {
                    const response = await this.updateOrganizationConfiguration({
                        organizationId: this.currentAgency.id,
                        configuration: this.config
                    });

                    this.alertState = !response ? 'ERROR' : 'SUCCESS';
                }
            } catch (err) {
                console.log(err);
            } finally {
                if(this.alertState) {
                    this.showAlert = true;
                    setTimeout(() => {
                        this.showAlert = false;
                    }, 5000);
                }
            }
        }
    }
};
</script>

<style lang="scss">
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;

  .v-input--selection-controls__input {
    margin-right: 0;
    margin-left: 8px;
  }
}
</style>
