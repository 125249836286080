<template>
    <div v-if="agencyHasDashboardWhiteLabelAccess">
        <styled-card>
            <template #heading>
                Organization Branding
            </template>
            <loader v-if="loading" />
            <div
                v-if="!loading"
                class="branding">
                <div class="branding__images">
                    <div class="branding__title">
                        Logos
                    </div>
                    <div class="branding__images-flex">
                        <div class="branding__image">
                            <asset-upload
                                :centered-text="true"
                                :centered-title="true"
                                :shortened="true"
                                :initial-image="primaryLogo"
                                :records="['agency_id']"
                                title="Primary"
                                :types="['jpeg', 'jpg', 'png', 'gif']"
                                @asset-uploaded-url="onPrimaryAssetUploaded" />
                        </div>
                        <div class="branding__image">
                            <asset-upload
                                :centered-text="true"
                                :centered-title="true"
                                :shortened="true"
                                :initial-image="faviconLogo"
                                :records="['agency_id']"
                                title="Favicon"
                                :types="['jpeg', 'jpg', 'png', 'gif']"
                                @asset-uploaded-url="onFaviconAssetUploaded" />
                        </div>
                        <div class="branding__image">
                            <asset-upload
                                :centered-text="true"
                                :centered-title="true"
                                :shortened="true"
                                :darked="true"
                                :initial-image="navbarLogo"
                                :records="['agency_id']"
                                title="Navbar"
                                :types="['jpeg', 'jpg', 'png', 'gif']"
                                @asset-uploaded-url="onNavbarAssetUploaded" />
                        </div>
                    </div>
                </div>
                <div class="branding__colors">
                    <div class="branding__title">
                        Colors
                    </div>
                    <div class="branding__colors-flex">
                        <div class="branding__color mb-4">
                            <editable-color-field
                                label="Primary Color"
                                :value="primaryColor"
                                :handler="setPrimaryColor" />
                            <action-button
                                v-clipboard:copy="primaryColor"
                                v-clipboard:success="onCopy"
                                class="copy-btn"
                                size="17"
                                icon="copy" />
                        </div>
                        <div class="branding__color mb-5">
                            <editable-color-field
                                label="Secondary Color"
                                :value="secondaryColor"
                                :handler="setSecondaryColor" />
                            <action-button
                                v-clipboard:copy="secondaryColor"
                                v-clipboard:success="onCopy"
                                class="copy-btn"
                                size="17"
                                icon="copy" />
                        </div>
                    </div>
                </div>
            </div>
        </styled-card>
    </div>
</template>

<script>
import StyledCard from '@/components/globals/StyledCard';
import AssetUpload from '@/components/globals/AssetUpload';
import Loader from '@/components/globals/Loader';
import EditableColorField from '@/components/globals/fields/EditableColorField';
import { mapGetters, mapState } from 'vuex';
import ActionButton from '@/components/globals/ActionButton';

export default {
    name: 'OrganizationBranding',
    components: {
        Loader,
        AssetUpload,
        StyledCard,
        EditableColorField,
        ActionButton
    },
    data() {
        return {
            primaryColor: null,
            secondaryColor: null,
            primaryLogo: null,
            faviconLogo: null,
            navbarLogo: null,
            loading: true
        };
    },
    async created() {
        this.loading = true;
        await this.$store.dispatch('updateCurrentAgency');
        await this.getAgencyBranding();
        this.loading = false;
    },
    computed: {
        ...mapState({
            agency: (state) => state.agency.currentAgency,
        }),
        ...mapGetters([
            'agencyHasDashboardWhiteLabelAccess'
        ]),
    },
    watch: {
        agency: {
            // This will let Vue know to look inside the array
            deep: true,

            handler() {
                this.loading = true;
                this.getAgencyBranding();
                this.loading = false;
            },
        },
    },
    methods: {
        async getAgencyBranding() {
            this.primaryColor = this.agency.white_label_config?.theme?.primary_color || '#808080';
            this.secondaryColor = this.agency.white_label_config?.theme?.secondary_color || '#808080';
            this.primaryLogo = this.agency.white_label_config?.asset_urls?.small_logo ?? null;
            this.faviconLogo = this.agency.white_label_config?.asset_urls?.favicon ?? null;
            this.navbarLogo = this.agency.white_label_config?.asset_urls?.navbar_logo ?? null;
        },
        setPrimaryColor(value) {
            this.primaryColor = value;
            this.updateWhiteLabelConfig();
        },
        setSecondaryColor(value) {
            this.secondaryColor = value;
            this.updateWhiteLabelConfig();
        },
        onPrimaryAssetUploaded(assetUrl) {
            this.primaryLogo = assetUrl;
            this.updateWhiteLabelConfig();
        },
        onFaviconAssetUploaded(assetUrl) {
            this.faviconLogo = assetUrl;
            this.updateWhiteLabelConfig();
        },
        onNavbarAssetUploaded(assetUrl) {
            this.navbarLogo = assetUrl;
            this.updateWhiteLabelConfig();
        },
        async updateWhiteLabelConfig() {
            const config = this.agency.white_label_config ?? {};
            const assetUrls = config.asset_urls ?? {};
            const theme = config.theme ?? {};

            if (this.primaryLogo != null) {
                assetUrls.small_logo = this.primaryLogo;
            }
            if (this.faviconLogo != null) {
                assetUrls.favicon = this.faviconLogo;
            }
            if (this.navbarLogo != null) {
                assetUrls.navbar_logo = this.navbarLogo;
            }
            if (this.primaryColor != null) {
                theme.primary_color = this.primaryColor;
            }
            if (this.secondaryColor != null) {
                theme.secondary_color = this.secondaryColor;
            }
            if (Object.keys(assetUrls).length >= 1) {
                config.asset_urls = assetUrls;
            }
            if (Object.keys(theme).length >= 1) {
                config.theme = theme;
            }

            await this.$http.put('/agencies/' + this.agency.id, {
                white_label_config: config
            });

            this.$store.dispatch('updateCurrentAgency');
        },
        onCopy() {
			this.$flash('Color copied to clipboard!', 'green');
		},
    }
};
</script>

<style lang="scss" scoped>
    .branding{
        display: flex;
        &__title{
            font-size: 16px;
            margin-bottom: 15px;
            margin-left: 5%;
        }
        &__images{
            width: 70%;
            padding: 30px 0 30px 30px;
        }
        &__images-flex{
            display: flex;
            justify-content: space-between;
            border-right: 1px solid #DFDFDF;
            padding-bottom: 15px;
            padding: 0 5%;
            padding-right: 10%;
        }
        &__image{
            width: 28%;
        }
        &__colors{
            width: 30%;
            padding-left: 30px;
            padding-top: 30px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
        &__colors-flex{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            padding-right: 60px;
            padding-top: 30px;
        }
        &__color{
            display: flex;
            align-items: flex-end;
            width: 50%;
            .copy-btn{
                margin-bottom: 5px;
            }
        }
    }
</style>