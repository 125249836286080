<template>
    <div>
        <div>
            <div
                v-if="loadingTokenDetails"
                class="text-xs-center">
                <loader />
            </div>
            <div
                v-else
                class="channel-panel">
                <div class="channel-panel__fields">
                    <div class="channel-panel__icon mr-5">
                        <div>
                            <img
                                class="channel-icon mr-2"
                                :src="'/img/platforms/pinterest.svg'">
                        </div>
                    </div>
                    <div class="channel-panel__item mr-5">
                        <data-field>
                            <template #label>
                                Status
                            </template>
                            <div class="layout align-center row">
                                <div v-if="!loadingTokenDetails && !isTokenDetailsValid">
                                    <template v-if="tokenDetails.status">
                                        <icon
                                            name="indicator-warning"
                                            class="mr-2"
                                            size="16px"
                                            color="yellow" />
                                        Not Used
                                    </template>
                                    <template v-else>
                                        <icon
                                            name="indicator-error"
                                            class="mr-2"
                                            size="16px"
                                            color="red" />
                                        Lost Connection
                                    </template>
                                </div>
                                <template v-else>
                                    <icon
                                        name="indicator-success"
                                        class="mr-2"
                                        size="16px"
                                        color="green" />
                                    Active
                                </template>
                            </div>
                        </data-field>
                    </div>
                    <div
                        v-if="tokenDetails.data"
                        class="channel-panel__item mr-5">
                        <data-field>
                            <template #label>
                                Connection User
                            </template>
                            <span>{{ tokenDetails.data.name }}</span>
                        </data-field>
                    </div>
                    <div
                        v-if="tokenDetails.data"
                        class="channel-panel__item">
                        <data-field v-if="tokenDetails.data.email">
                            <template #label>
                                Email
                            </template>
                            <span>{{ tokenDetails.data.email }}</span>
                        </data-field>
                    </div>
                </div>
                <div class="channel-panel__actions">
                    <div
                        v-if="tokenDetails.data && userIsAdmin"
                        class="channel-panel__action mr-5">
                        <action-button
                            v-clipboard:copy="tokenDetails.data.token"
                            v-clipboard:success="onCopy"
                            icon="lock">
                            Token
                        </action-button>
                    </div>
                    <div v-if="!loadingTokenDetails && !isTokenDetailsValid && tokenDetails.status" />
                    <div
                        v-else
                        class="channel-panel__action">
                        <action-button
                            icon="data-remap"
                            :loading="loadingTokenDetails"
                            @click="changeTokenSlideOut = true">
                            Change user
                        </action-button>
                    </div>
                </div>
            </div>
            <div
                v-if="!loadingTokenDetails && !isTokenDetailsValid">
                <div v-if="tokenDetails.status" />
                <div
                    v-else
                    class="wrong-wrapper">
                    <div class="wrong-text">
                        BuyerBridge has lost its connection to Pinterest. Click the button to the right to re-connect it.
                    </div>
                    <div class="wrong-button">
                        <button @click="changeTokenSlideOut = true">
                            RE-CONNECT
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <platform-authentication-slide-out
            v-if="!loadingTokenDetails"
            v-model="changeTokenSlideOut"
            :channel="channel" />
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import getTokenDetails from '@/apis/pinterest/getTokenDetails';
import DataField from '@/components/globals/DataField';
import Loader from '@/components/globals/Loader';
import { mapState, mapGetters } from 'vuex';
import PlatformAuthenticationSlideOut from '@/components/resellers/settings/PlatformAuthenticationSlideOut';
import { getChannelByPlatformName } from '@/components/onboarding/channel-setup/channels.js';
import Icon from '@/components/globals/Icon';
export default {
	components: {
		ActionButton,
		DataField,
		Loader,
		PlatformAuthenticationSlideOut,
        Icon
	},
	data() {
		return {
			tokenDetails: {},
			changeTokenSlideOut: false,
			channel: {},
			loadingTokenDetails: false,
			showAreYouSure: false,
		};
	},
	computed: {
		...mapState({
			currentAgency: (state) => state.agency.currentAgency,
		}),
		...mapGetters(['userIsAdmin']),
        isTokenDetailsValid() {
            return (this.tokenDetails.status !== 'success' || !Object.keys(this.tokenDetails).length) ? false : true;
        }
	},
	watch: {
		currentAgency: {
			// This will let Vue know to look inside the array
			deep: true,

			handler() {
				this.fetchTokenDetails();
			},
		},
	},
	created() {
		this.fetchTokenDetails();
		this.channel = getChannelByPlatformName('pinterest');
	},
	methods: {
		onCopy() {
			this.$flash('Token copied to clipboard!', 'green');
		},
		async fetchTokenDetails() {
			try {
				this.loadingTokenDetails = true;
				this.tokenDetails = {};
				this.tokenDetails = await getTokenDetails(this.currentAgency.id);
			} catch (error) {
				console.log(error);
			} finally {
				this.loadingTokenDetails = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.channel-icon {
    width: 50px;
    height: auto;
}
.channel-panel{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 15px;
    border-bottom: 1px solid #DFDFDF;
    &__actions{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    &__fields{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}
.wrong-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F8CBCB;
    border: 1px solid #E11C1A;
    padding: 15px 30px;
}
.wrong-button button{
    background: #EF5542;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 15px;
}
</style>