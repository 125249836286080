<template>
    <v-flex>
        <v-layout
            pr-3
            justify-end>
            <action-button
                v-if="agencyHasWhiteLabelAccess"
                class="mb-3"
                icon="cog"
                @click="organizationSettingsSlideoutOpen = !organizationSettingsSlideoutOpen">
                Settings
            </action-button>
        </v-layout>
        <organization-settings-slideout
            v-model="organizationSettingsSlideoutOpen" />
        <organization-branding />
        <styled-card class="mt-5 mb-5">
            <template #heading>
                <h4>Channel Connection</h4>
            </template>
            <div class="channels-wrapper">
                <facebook-token-card />

                <tiktok-token-card />

                <pinterest-token-card />

                <snapchat-token-card />
            </div>
        </styled-card>

        <platform-authentication-slide-out
            v-model="showFacebookTokenSlideout"
            :channel="{ name: 'facebook', display_name: 'Facebook' }" />
    </v-flex>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import FacebookTokenCard from '@/components/resellers/settings/FacebookTokenCard.vue';
import SnapchatTokenCard from '@/components/resellers/settings/SnapchatTokenCard.vue';
import PinterestTokenCard from '@/components/resellers/settings/PinterestTokenCard.vue';
import TiktokTokenCard from '@/components/resellers/settings/TiktokTokenCard.vue';
import OrganizationSettingsSlideout from '@/components/globals/OrganizationSettingsSlideout';
import { mapGetters, mapState } from 'vuex';
import { TOGGLE_ORGANIZATION_SETTINGS_SLIDEOUT } from '@/store/mutation-types';
import orgConfiguration from '@/helpers/organizations/configuration';
import OrganizationBranding from './OrganizationBranding';
import StyledCard from '@/components/globals/StyledCard';
import PlatformAuthenticationSlideOut from '@/components/resellers/settings/PlatformAuthenticationSlideOut.vue';

export default {
    title: 'Global Dashboard',
    components: {
        OrganizationBranding,
        ActionButton,
        OrganizationSettingsSlideout,
        StyledCard,
        FacebookTokenCard,
        SnapchatTokenCard,
        PinterestTokenCard,
        TiktokTokenCard,
        PlatformAuthenticationSlideOut
    },
    data() {
        return {
            orgConfiguration,
            showFacebookTokenSlideout: false,
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency,
        }),
        ...mapGetters([
            'dealerPlatforms',
            'agencyHasWhiteLabelAccess'
        ]),
        organizationSettingsSlideoutOpen: {
            set(value) {
                this.$store.commit(TOGGLE_ORGANIZATION_SETTINGS_SLIDEOUT, value);
            },
            get() {
                return this.$store.state.settings.organizationSettingsSlideoutOpen;
            }
        }
    },
    created() {
        // Find the facebookToken query param and open the appropriate slideout
        if ((!this.currentAgency?.facebook_business_id) && this.$route.query.token) {
            // The FacebookTokenSwap.vue component has the logic to
            // select Facebook BM for the agency
            this.showFacebookTokenSlideout = true;
        }
    },
};
</script>

<style lang="scss" scoped>
.channels-wrapper{
    padding: 30px 60px;
}
</style>
