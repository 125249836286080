<template>
    <div>
        <div>
            <div
                v-if="loadingTokenDetails"
                class="text-xs-center">
                <loader />
            </div>
            <div
                v-else
                class="channel-panel">
                <div class="channel-panel__fields">
                    <div class="channel-panel__icon mr-5">
                        <div>
                            <img
                                class="channel-icon mr-2"
                                :src="'/img/platforms/meta.svg'">
                        </div>
                    </div>
                    <div class="channel-panel__item mr-5">
                        <data-field>
                            <template #label>
                                Status
                            </template>
                            <div class="layout align-center row">
                                <div v-if="!loadingTokenDetails && !isTokenDetailsValid">
                                    <template v-if="tokenStatusSuccess">
                                        <icon
                                            name="indicator-warning"
                                            class="mr-2"
                                            size="16px"
                                            color="yellow" />
                                        Not Used
                                    </template>
                                    <template v-else>
                                        <icon
                                            name="indicator-error"
                                            class="mr-2"
                                            size="16px"
                                            color="red" />
                                        Lost Connection
                                    </template>
                                </div>
                                <template v-else>
                                    <template v-if="agencyHasBmSelected">
                                        <icon
                                            name="indicator-success"
                                            class="mr-2"
                                            size="16px"
                                            color="green" />
                                        Active
                                    </template>
                                    <template v-else>
                                        <action-button
                                            class="mr-2"
                                            icon="indicator-question"
                                            color="blue"
                                            :loading="loadingBusinessManagerDetails"
                                            size="16"
                                            @click="selectFacebookBusinessManagerSlideOut = true" />
                                        Pending Business Manager Selection
                                    </template>
                                </template>
                            </div>
                        </data-field>
                    </div>
                    <div
                        v-if="businessManagerDetails"
                        class="channel-panel__item mr-5">
                        <data-field>
                            <template #label>
                                Business Manager
                            </template>
                            <span
                                v-if="agencyHasBmSelected">
                                <span>{{ businessManagerDetails.name }}</span>
                                <span> ({{ businessManagerDetails.id }})</span>
                                <span class="ml-2">
                                    <action-button
                                        v-clipboard:copy="businessManagerDetails.id"
                                        v-clipboard:success="onCopy"
                                        size="17"
                                        icon="copy" />
                                </span>
                            </span>
                            <span v-else>
                                <span class="mr-2">
                                    <action-button
                                        icon="indicator-question"
                                        :loading="loadingBusinessManagerDetails"
                                        size="16"
                                        @click="selectFacebookBusinessManagerSlideOut = true" />
                                </span>
                                <span>Not Selected</span>
                            </span>
                        </data-field>
                    </div>
                    <div
                        v-if="tokenDetails"
                        class="channel-panel__item mr-5">
                        <data-field>
                            <template #label>
                                BM Verification
                            </template>
                            <div class="layout align-center row">
                                <template v-if="businessManagerDetails.verification_status === 'not_verified'">
                                    <icon
                                        name="indicator-error"
                                        class="mr-2"
                                        size="16px"
                                        color="red" />
                                    Not verified
                                </template>
                                <template v-else-if="businessManagerDetails.verification_status === 'verified'">
                                    <icon
                                        name="indicator-success"
                                        class="mr-2"
                                        size="16px"
                                        color="green" />
                                    Verified
                                </template>
                                <template v-else>
                                    <action-button
                                        class="mr-2"
                                        icon="indicator-question"
                                        :loading="loadingBusinessManagerDetails"
                                        size="16"
                                        @click="selectFacebookBusinessManagerSlideOut = true" />
                                    Unknown
                                </template>
                            </div>
                        </data-field>
                    </div>
                    <div
                        v-if="tokenDetails"
                        class="channel-panel__item">
                        <data-field>
                            <template #label>
                                Connection User
                            </template>
                            <span>{{ tokenDetails.name }}</span>
                            <span> ({{ tokenDetails.id }})</span>
                            <span class="ml-2">
                                <action-button
                                    v-clipboard:copy="tokenDetails.id"
                                    v-clipboard:success="onCopy"
                                    size="17"
                                    icon="copy" />
                            </span>
                        </data-field>
                    </div>
                </div>
                <div
                    v-if="tokenDetails"
                    class="channel-panel__actions">
                    <div
                        v-if="tokenDetails.token && userIsAdmin"
                        class="channel-panel__action mr-5">
                        <action-button
                            v-clipboard:copy="tokenDetails.token"
                            v-clipboard:success="onCopy"
                            icon="lock">
                            Token
                        </action-button>
                    </div>
                    <div class="channel-panel__action">
                        <action-button
                            icon="data-remap"
                            :loading="loadingTokenDetails"
                            @click="changeTokenSlideOut = true">
                            Change user
                        </action-button>
                    </div>
                </div>
            </div>
            <div
                v-if="!loadingTokenDetails && !isTokenDetailsValid">
                <div class="wrong-wrapper">
                    <div class="wrong-text">
                        BuyerBridge has lost its connection to Meta. Click the button to the right to re-connect it.
                    </div>
                    <div class="wrong-button">
                        <button @click="changeTokenSlideOut = true">
                            RE-CONNECT
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <platform-authentication-slide-out
            v-if="!loadingTokenDetails"
            v-model="changeTokenSlideOut"
            :channel="channel" />

        <styled-slideout
            v-if="!loadingTokenDetails"
            v-model="selectFacebookBusinessManagerSlideOut">
            <select-facebook-business-manager
                :token-details="tokenDetails"
                @closeSlideout="selectFacebookBusinessManagerSlideOut = false" />
        </styled-slideout>
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import Icon from '@/components/globals/Icon';
import getTokenDetails from '@/apis/facebook/getTokenDetails';
import DataField from '@/components/globals/DataField';
import Loader from '@/components/globals/Loader';
import { mapState, mapGetters } from 'vuex';
import { PLATFORM_FACEBOOK } from '@/helpers/globals';
import { getChannelByPlatformName } from '@/components/onboarding/channel-setup/channels.js';
import PlatformAuthenticationSlideOut from '@/components/resellers/settings/PlatformAuthenticationSlideOut';
import StyledSlideout from '@/components/globals/StyledSlideout';
import SelectFacebookBusinessManager from '@/components/resellers/settings/SelectFacebookBusinessManager.vue';

export default {
    components: {
        ActionButton,
        Icon,
        DataField,
        Loader,
        PlatformAuthenticationSlideOut,
        StyledSlideout,
        SelectFacebookBusinessManager
    },
    data() {
        return {
            tokenDetails: {},
            changeTokenSlideOut: false,
            loadingTokenDetails: false,
            businessManagerDetails: {},
            loadingBusinessManagerDetails: false,
            channel: {},
            selectFacebookBusinessManagerSlideOut: false
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency
        }),
        ...mapGetters([
            'userIsAdmin'
        ]),
        isTokenDetailsValid() {
            return (!this.tokenStatusSuccess || !this.tokenDetails?.id) ? false : true;
        },
        tokenStatusSuccess() {
            return this.tokenDetails?.status === 'success';
        },
        agencyHasBmSelected() {
            return this.businessManagerDetails?.id ? true : false;
        }
    },
    watch: {
        currentAgency: {
            // This will let Vue know to look inside the array
            deep: true,
            // We have to move our method to a handler field
            handler() {
                this.fetchTokenDetails();
            }
        },
    },
    created() {
        this.fetchTokenDetails();
        this.channel = getChannelByPlatformName('facebook');
    },
    methods: {
        onCopy() {
            this.$flash('Copied to clipboard!', 'green');
        },
        async fetchTokenDetails() {
            try {
                this.loadingTokenDetails = true;
                this.tokenDetails = {};
                let reponse = await getTokenDetails(this.currentAgency.id);
                this.tokenDetails = reponse?.data;
                this.tokenDetails.status = reponse?.status;
            } catch (error) {
                console.log(error);
            } finally {
                this.loadingTokenDetails = false;
            }

            await this.fetchBusinessManagerDetails();
        },
        async fetchBusinessManagerDetails() {
            try {
                this.loadingBusinessManagerDetails = true;
                this.businessManagerDetails = {};
                let reponse = await this.$apiRepository.getPlatformBusinessManagerDetails({
                    agency_id: this.currentAgency.id,
                    platform: PLATFORM_FACEBOOK,
                });
                this.businessManagerDetails = reponse.data;
            } catch (error) {
                console.log(error);
            } finally {
                this.loadingBusinessManagerDetails = false;
                // If its Facebook and no BM details are returned but we do have token
                // show the buisness manager slideout so the user can select the BM
                if (this.tokenDetails?.id && this.channel?.key === PLATFORM_FACEBOOK && !this.businessManagerDetails?.id) {
                    this.selectFacebookBusinessManagerSlideOut = true;
                }
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.fb-button {
  border-radius: 7px;
  text-align: center;
  background-color: #1A77F2;
  padding: 4px;
  color: white;
  font-weight: 700;
  font-size: 17px;
  text-decoration: none;
}
.channel-icon {
    width: 50px;
    height: auto;
}
.channel-panel{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 15px;
    border-bottom: 1px solid #DFDFDF;
    &__actions{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    &__fields{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}
.wrong-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F8CBCB;
    border: 1px solid #E11C1A;
    padding: 15px 30px;
}
.wrong-button button{
    background: #EF5542;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 15px;
}
</style>