<template>
    <div class="pa-4 dark-text">
        <div>
            <h2 class="pb-3">
                Select Business Manager
            </h2>

            <div class="mb-4">
                <p>Select the Meta business manager you would like BuyerBridge to use. This will be the Meta business manager that BuyerBridge publish Meta assets like catalogs, pixels and ad accounts into.</p>
            </div>

            <v-alert
                class="ma-4"
                :value="gettingBusinessManagersError"
                color="error"
                icon="warning"
                outline>
                Error getting Meta Business Managers. Please contact support.
            </v-alert>

            <v-autocomplete
                v-model="selectedBusinessManager"
                :items="businessManagers"
                :loading="loadingBusinessManagers"
                label="Select Your Business Manager"
                class="styled-field ma-4"
                item-text="name"
                return-object>
                <template
                    slot="item"
                    slot-scope="businessManagers">
                    <v-list-tile-content>
                        <v-list-tile-title>{{ businessManagers.item.name }}</v-list-tile-title>
                        <v-list-tile-sub-title class="grey--text text--lighten-2">
                            {{ businessManagers.item.id }}
                        </v-list-tile-sub-title>
                    </v-list-tile-content>
                </template>
            </v-autocomplete>

            <div class="text-center">
                <styled-button
                    v-if="!selectBmLoading"
                    :disabled="!selectedBusinessManager"
                    class="play-button ma-4"
                    type="submit"
                    @click="selectBmId">
                    YES THIS IS MY BUSINESS MANAGER
                </styled-button>
                <loader
                    v-if="selectBmLoading"
                    class="pa-5" />
            </div>

            <v-alert
                class="ma-4"
                :value="changeTokenError"
                color="error"
                icon="warning"
                outline>
                Error setting Business Manager. Please contact support.
            </v-alert>
        </div>
    </div>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import Loader from '@/components/globals/Loader.vue';
import { mapState, mapGetters } from 'vuex';
export default {
    components: {
        StyledButton,
        Loader
    },
    props: {
        tokenDetails: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            changeTokenError: false,
            changeTokenSuccess: false,
            loadingTokenDetails: false,
            businessManagers: [],
            selectedBusinessManager: null,
            gettingBusinessManagersError: false,
            loadingBusinessManagers: false,
            selectBmLoading: false,
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency
        }),
        ...mapGetters([
            'userIsAdmin'
        ]),
    },
    created() {
        this.getBms();
    },
    methods: {
        checkForBmSelected() {
            if (this.tokenDetails?.business_manager_id) {
                this.selectedBusinessManager = this.businessManagers.find(bm => bm.id === this.tokenDetails.business_manager_id);
            }
        },
        async getBms() {
            try {
                this.gettingBusinessManagersError = false;
                this.loadingBusinessManagers = true;
                const response = await this.$apiRepository.getFacebookBusinessManagers({
                    'token': this.tokenDetails.token
                });
                this.businessManagers = response.data.businesses.data;
                this.selectedBusinessManager = this.businessManagers[0] ?? null;
            } catch (error) {
                this.gettingBusinessManagersError = true;
                console.log(error);
            } finally {
                this.loadingBusinessManagers = false;
            }
        },
        async selectBmId() {
            // Make the api call to set the BM Id for the agency and set the Facebook token
            try {
                this.selectBmLoading = true;
                this.changeTokenSuccess = false;
                this.changeTokenError = false;
                await this.$apiRepository.setFbBmAndToken({
                    'agency_id': this.currentAgency.id,
                    'business_manager_id': this.selectedBusinessManager.id,
                    'token': this.tokenDetails.token
                });
                // Show success alert
                this.changeTokenSuccess = true;

                // Refresh the page
                this.$router.go();
            } catch (error) {
                this.changeTokenError = true;
                console.log(error);
            } finally {
                this.selectBmLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.fb-button {
  border-radius: 7px;
  text-align: center;
  background-color: #1A77F2;
  padding: 4px;
  color: white;
  font-weight: 700;
  font-size: 17px;
  text-decoration: none;
}
.channel-icon {
    width: 50px;
    height: auto;
}
.channel-panel{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 15px;
    border-bottom: 1px solid #DFDFDF;
    &__actions{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    &__fields{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}
.wrong-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F8CBCB;
    border: 1px solid #E11C1A;
    padding: 15px 30px;
}
.wrong-button button{
    background: #EF5542;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 15px;
}

.dark-text {
    color: black;
}
</style>